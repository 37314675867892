<template>
  <div>
    <div id="NavrightHome"></div>
    <div id="menuRight">
      <div class="menuChose" v-bind:class="{ active : menuHamburger }">
        <b-row>
          <b-col col lg="3">
            <!-- <img :src="imgThemes" alt> -->
          </b-col>
          <b-col col lg="4">
            <ul>
              <li
                v-for="(item, index) in menuChose"
                :key="index"
                @click="showDataChose(index)"
                :class="{isComing : (isComingSoon==index) && (index != 0 &&index != 1 && index != 2 && index != 3 && index != 4) }"
              >
                <router-link :to="item.link">
                  <i class="fas fa-arrow-right"></i>
                  {{item.title}}
                </router-link>

                <img :src="coming" class="imgSoon" alt="coming">
              </li>
              <div class="img-map">
                <router-link :to="'/map'">
                <svg xmlns="http://www.w3.org/2000/svg" width="135.024" height="68.176" viewBox="0 0 135.024 68.176">
                  <g id="Group_583" data-name="Group 583" transform="translate(-729.195 -565.068)">
                    <path id="Path_41" data-name="Path 41" d="M-204.344,159.72l7.948,8.112,2.512,8.644-2.054,4.378,1.778,4.1,17.186,8.206,3.777,9.6,23.483,2.577,16.119,5.971,8.023-4.508,15.337-3.36,7.586-7.414-2.747-4.058,3.661-5.37,6.266,1.956,9.977-7.439,4.819-6.1,8.931-1.659.421-2.074-6.86-5.393-3.285.54-3.53,1.287-3.9,1.714-1.063-4.713,2.073-9.136-10.056-.955-13.086,8.487L-121.2,161.55l-11.979-5.583-6.591,2.207-5.519-3.112-.747-4.76-12.005-5.172-4.08,4.771.822,4.816-4.062,4.876-10.917-3.52L-177,153.12l-7.06-2.88-16.256,5.908Z" transform="translate(934.539 420.939)" fill="#fff" stroke="#2c2c2c" stroke-linejoin="round" stroke-width="2"/>
                    <text id="MAP" transform="translate(770 608)" fill="#2c2c2c" font-size="22" font-family="Poppins-Bold, Poppins" font-weight="700"><tspan x="0" y="0">MAP</tspan></text>
                  </g>
                </svg>
                </router-link>
              </div>

            </ul>
          </b-col>
          <b-col col lg="5">
            <ul>
              <li v-for="(item, index) in travelThemes" :key="index" class="list-house" :style="'font-weight: normal;'">
                <router-link :to="item.path">
                  <svg id="Group_46" data-name="Group 46" xmlns="http://www.w3.org/2000/svg" width="64.111" height="43.491" viewBox="0 0 64.111 43.491">
                     <path id="Path_784" data-name="Path 784" fill="#fff" d="M864.905,187.653s-6.695-2.247-8.722-3.491a18.242,18.242,0,0,1-.5,2.342c2,.914,6.05,2.639,7.771,2.407,0,0-.757,1.82,1.694,1.82h2.078S867.665,188.911,864.905,187.653Z" transform="translate(-828.024 -154.812)"/>
                    <path id="Path_785" data-name="Path 785" fill="#fff" d="M855.045,125.339a37.865,37.865,0,0,0-3.943-3.255l-1.249-2.234-.564,2.234-1.437-2.192-.064,3.152a6.634,6.634,0,0,0-.748-.11,6.869,6.869,0,0,0-1.789-2.147,2.507,2.507,0,0,0-.753-.412,2.358,2.358,0,0,0-1.53.1,7,7,0,0,0-1.359.764A8.488,8.488,0,0,1,845.3,122.9c-.2.01-.406.026-.622.048a6.328,6.328,0,0,0-3.211-1.368,8.968,8.968,0,0,0-3.492,2.382c-.224.255-.445.6-.313.912l-.459-.19a11.321,11.321,0,0,0-2.968,3.142c.341.142.67.292.992.455a21.453,21.453,0,0,0-2.392,3.632s-5.758.75-9.014-.377c0,0-7.383-3.756-13.642,1.5,0,0-12.267,8.141-15.142,1.88,0,0-.5,8.136,7.633,6.51,0,0,5.511-1.229,6.6-5.023-.193.606-.911,3.867,4.1,8.3,1.25,1.1,3.754,3.691,3.754,3.691s.625,4.073,2.443,6.426c0,0,5.757,6.137,7.26,6.635,0,0-1.134,1.879,1.942,1.879h1.625s.943-.436-.874-2.191c0,0-6.238-5.116-7.825-7.264-.431-.579-1-2.065-.124-2.877a11.4,11.4,0,0,0,.951-3.562,4.429,4.429,0,0,1,.053-.785A11,11,0,0,0,820.488,140a8.1,8.1,0,0,1,2.846,5.791,1.2,1.2,0,0,1,.546.146c2.589,1.267,6.268,1.9,11.235,1.364a17.787,17.787,0,0,1-1.752-4.214,12.2,12.2,0,0,0,2.982,4.207,28.252,28.252,0,0,0,5.625,2.335s1.314,2.381-4.317,2.128c0,0,.625-1.75-2.5-1.75-1.315,0-2.5.5.938,2.754a3.918,3.918,0,0,0,3.878.316s7.889-1.128,3.57-4.76c0,0-3.71-2.128-.063-2.128a16.576,16.576,0,0,1,4.7-.672s1.314-.707,1,5.3c0,0-.629.439.06,1.44,0,0,.126,1.065-.751.752,0,0-1.622-.126.628,2.129,0,0,1.127,1.249,1.38-.314l.247-9.395s.442-3.08-2.94-2.441c-1.082.2-3.442,1.344-3.941-1.983a11.319,11.319,0,0,1,4.569-11.165s3.006,1.813,5.82.5c0,0,2.565,2.229,4.255.25C859.506,129.4,859.931,129.541,855.045,125.339Z" transform="translate(-795.028 -119.85)"/>
                  </svg>
                  &nbsp;{{item.title}}
                </router-link>
              </li>
            </ul>
          </b-col>
        </b-row>
      </div>

      <div id="travel" v-bind:class="{ travel : menuTravel }" @mouseleave="hideCaseTravel">
        <router-link
          class="travel-chose"
          :to="item.path"
          :style="{backgroundImage: 'url('+item.img+')'}"
          v-for="(item, index) in menuChose[1].dataChose"
          :key="index"
        >
          <div :style="{backgroundColor: item.rgba}">
            <div class="detail">
              <p class="detail-title">{{ item.title }}</p>
              <p
                class="detail-hover"
              >{{item.description}}</p>
            </div>
          </div>
        </router-link>
      </div>
      <div id="regions" v-bind:class="{ regions : menuRegions }" @mouseleave="hideCaseRegions">
        <div class="regions-detail text-center">
          <h1>MONGOLIA</h1>
          <RegionMap @hover="onHoverMap"/>
          <div v-if="selectedRegion">
            <h3>{{selectedRegion.title}}</h3>
            <p class="text-left">{{selectedRegion.description}}</p>
          </div>
        </div>
      </div>

      <div class="NavRight">
        <div @mouseover="showCaseTravel">TRAVEL THEMES</div>
        <div @mouseover="showCaseRegions">REGIONS</div>
      </div>

      <div class="menuhamburger" v-bind:class="{ change : menuHamburger }" @click="changeHamburger">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
      </div>
    </div>
  </div>
</template>

<script>
import travel01 from "../assets/img/travel01.jpg";
import travel02 from "../assets/img/travel02.jpg";
import travel03 from "../assets/img/travel03.jpg";
import travel04 from "../assets/img/travel04.jpg";
import travel05 from "../assets/img/travel05.jpg";
import travel06 from "../assets/img/travel06.jpg";
import travel07 from "../assets/img/travel07.jpg";
import travel08 from "../assets/img/travel08.jpg";
import travel09 from "../assets/img/travel09.jpg";
import travel10 from "../assets/img/travel10.jpg";
import coming1 from "../assets/img/coming-soon.png";
import RegionMap from "@/components/RegionMap";
import mapMongolia from "../assets/img/map-mongolia.png";

export default {
  name: "NavrightHome",
  components: {
    RegionMap
  },
  data() {
    return {
      menuTravel: false,
      menuRegions: false,
      menuHamburger: false,
      imgMapRegions: mapMongolia,
      travelThemes: [],
      imgThemes: mapMongolia,
      coming: coming1,
      isComingSoon: "on",
      selectedRegion: null,
      house: '#FFF',
      menuChose: [
        {
          title: "Traveler Snapshots",
          link: "/traveler-snapshots",
          dataChose: [{ title: "" }]
        },
        {
          title: "Travel Themes",
          link: "",
          dataChose: [
            {
              title: "Festivals",
              rgba: "rgba(255, 48, 54, 0.8)",
              img: travel01,
              path: "/themes/festivals",
              description: "Mongolia has many famous festivals. Find out when and where you can emerge yourself in the festivities."
            },
            {
              title: "Family Activities",
              rgba: "rgba(52, 103, 159, 0.8)",
              img: travel02,
              path: "/themes/family-activities",
              description: "Families are important to us. Mongolians has strong family bonds and we welcome anyone regardless of age"
            },
            {
              title: "Bird Watching",
              rgba: "rgba(34, 139, 91, 0.8)",
              img: travel03,
              path: "/themes/bird-watching",
              description: "We are a paradise for birding. Find out why you should come to Mongolia to watch birds from all over the world."
            },
            {
              title: "Nature",
              rgba: "rgba(213, 117, 4, 0.8)",
              img: travel04,
              path: "/themes/nature",
              description: "Mongolia is nature. Nature is Mongolia. Come to the land of endless steppes, forests and mountains."
            },
            {
              title: "History & Culture",
              rgba: "rgba(16, 82, 59, 0.8)",
              img: travel05,
              path: "/themes/history",
              description: "We are one of the oldest cultures in the region. Explore more about our story and come to see it for yourself."
            },
            {
              title: "Buddhist Tourism",
              rgba: "rgba(213, 157, 4, 0.8)",
              img: travel06,
              path: "/themes/buddhist-tourism",
              description: "With numerous temples and relicts, Mongolia is a spiritual place to visit and explore yourself."
            },
            {
              title: "Taste of Mongolia",
              rgba: "rgba(95, 18, 15, 0.8)",
              img: travel07,
              path: "/themes/taste",
              description: "We have dishes you have never tasted anywhere else. Find out more about Mongolian cuisine here."
            },
            {
              title: "Community Based Tourism",
              rgba: "rgba(145, 20, 87, 0.8)",
              img: travel08,
              path: "/themes/community-based",
              description: "Our communities welcome you to Mongolia. Find out how you can experiences the nomadic way of life."
            },
            {
              title: "Adventure",
              rgba: "rgba(52, 10, 78, 0.8)",
              img: travel09,
              path: "/themes/adventure",
              description: "Ride through the wide country, take a road trip of your lifetime or hike through the mountains. Mongolia is a haven for adventures. Come and find yours."
            },
            {
              title: "MICE",
              rgba: "rgba(34, 47, 106, 0.8)",
              img: travel10,
              path: "/themes/mice",
              description: "Though the meetings, incentive, conference, and exhibition sector is still new for us. Mongolia offers a number of venues and activities that make your event outstanding."
            }
          ]
        },
        {
          title: "Regions",
          link: "",
          dataChose: [
            {
              title: "Ulaanbaatar",
              rgba: "rgba(255, 48, 54, 0.8)",
              img: travel01,
              path: '/regions/ulaanbaatar'
            },
            {
              title: "Central",
              rgba: "rgba(52, 103, 159, 0.8)",
              img: travel02,
              path: '/regions/central'
            },
            {
              title: "Eastern",
              rgba: "rgba(34, 139, 91, 0.8)",
              img: travel03,
              path: '/regions/eastern'
            },
            {
              title: "Khangai",
              rgba: "rgba(213, 117, 4, 0.8)",
              img: travel04,
              path: '/regions/khangai'
            },
            {
              title: "Gobi",
              rgba: "rgba(16, 82, 59, 0.8)",
              img: travel05,
              path: '/regions/gobi'
            }
          ]
        },
        {
          title: "Events Calendar",
          link: "/event-calendar",
          dataChose: [{ title: "" }]
        },
        {
          title: "Plan Your Trip",
          link: "/plan",
          dataChose: [{ title: "" }]
        },
        {
          title: "Offers",
          link: "",
          dataChose: [{ title: "" }]
        },
        {
          title: "Contact",
          link: "/contact",
          dataChose: [{ title: "" }]
        }
      ]
    };
  },
  methods: {
    mouseover: function(){
      this.house = '#E13036'
    },    
    mouseleave: function(){
      this.house = '#fff'
    },
    showCaseTravel() {
      this.menuTravel = true;
      this.menuRegions = false;
    },
    hideCaseTravel() {
      this.menuTravel = false;
      this.menuRegions = false;
    },
    showCaseRegions() {
      this.menuRegions = true;
      this.menuTravel = false;
    },
    hideCaseRegions() {
      this.menuTravel = false;
      this.menuRegions = false;
    },
    changeHamburger() {
      this.menuHamburger = !this.menuHamburger;
      this.travelThemes = [];
      this.imgThemes = mapMongolia;
    },
    showDataChose(index) {
      this.travelThemes = this.menuChose[index].dataChose;
      this.imgThemes = mapMongolia;
      this.isComingSoon = index;
      setTimeout(() => {
        this.isComingSoon = "on";
      }, 1000);
    },
    showImage(img) {
      this.imgThemes = img;
    },
    onHoverMap(region) {
      this.selectedRegion = region;
    }
  },
  mounted() {
    const locations = this.$store.state.ref.locations;
    if (locations && locations.length > 0) {
      this.selectedRegion = locations[0];
    }
  }
};
</script>

<style lang="scss" scoped>
#NavrightHome {
  z-index: 1010;
  background-color: #ffffff;
  height: 100%;
  width: 40px;
  position: fixed;
  top: 0;
  right: 0;
}

#menuRight {
  z-index: 1011;
  height: 100%;
  position: fixed;
  top: 0;
  right: -223px; 

  .NavRight {
    position: relative;
    right: 120px;
    top: 50%;
    transform: translate(-50%);
    transform: rotate(90deg);
    cursor: pointer;
    div {
      color: #000;
      font-size: 16px;
      display: inline-block;
      margin: 0 20px;
      padding-bottom: 10px;
      &:hover {
        color: red;
      }
    }
  }
  
  .list-house {
    font-weight: normal;
  }

  #travel {
    position: fixed;
    right: 40px;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.3s ease;
    display: flex;
    flex-wrap: wrap;

    .travel-chose {
      display: block;
      opacity: 0;
      transition: opacity 0.3s ease;
      width: 50%;
      height: 20%;
      background-size: cover;

      &:nth-child(1n + 0) {
        border-top: 1px solid #ffffff;
        border-right: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
      }

      &:nth-child(2n + 0) {
        border-top: 1px solid #ffffff;
        border-left: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
      }

      &:nth-child(1),
      &:nth-child(2) {
        border-top: 0;
      }

      &:nth-child(9),
      &:nth-child(10) {
        border-bottom: 0;
      }

      div {
        height: 100%;
        padding: 0 5px;

        .detail {
          position: relative;
          .detail-title {
            transition: bottom 0.1s linear;
            margin: 0;
            position: absolute;
            bottom: 0px;
            color: #fff;
            font-size: 22px;
            font-weight: bold;
            text-transform: uppercase;
          }

          .detail-hover {
            transition: opacity 0.1s ease-in;
            color: #fff;
            opacity: 0;
            font-size: 0.78125vw;
            position: absolute;
            bottom: 0px;
          }
        }

        &:hover {
          .detail-title {
            bottom: 75%;
          }
          .detail-hover {
            opacity: 1;
          }
        }
      }
    }

    &.travel {
      width: 40%;

      .travel-chose {
        opacity: 1;
        transition: opacity 0.8s ease;
      }
    }
  }

  #regions {
    position: fixed;
    right: 40px;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: #e13036;
    transition: width 0.3s ease;

    .regions-detail {
      opacity: 0;
      transition: opacity 0.3s ease;
      padding-top: 100px;
      padding-left: 70px;
      padding-right: 70px;
      color: #fff;

      img {
        max-width: 100%;
      }
    }

    &.regions {
      width: 40%;
      .regions-detail {
        opacity: 1;
        transition: opacity 0.8s ease;
      }
    }
  }

  .menuChose {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s, opacity 0.3s ease;

    .col {
      &:nth-child(1) {
        padding-left: 60px;
        padding-right: 60px;
      }

      img {
        width: 100%;
        position: relative;
        top: 50%;
        transform: translate(0, -50%);
        opacity: 0;
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin-top: 110px;
        opacity: 0;

        li {
          position: relative;

          a {
            color: #000;
            text-decoration: none;
            &:hover {
              color: #e13036;

              svg {
                path {
                  fill: #e13036;
                }
              }
            }
          }
          i {
            color: #ffffff;
          }

          .imgSoon {
            z-index: 1;
            opacity: 0;
            width: inherit;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: 0.3s ease-in-out;
          }

          &.isComing {
            .imgSoon {
              opacity: 1;
            }
          }
        }
      }
    }

    &.active {
      opacity: 1;
      visibility: visible;
      z-index: 12;

      .row {
        height: 100%;
      }

      .col {
        &:nth-child(1) {
          // background-color: #e13036;
          background-image: url("../assets/img/bg-menuleft.png");
          background-repeat: no-repeat;
          background-size: cover;
          height: 100%;
          width: 100%;
        }

        img {
          opacity: 1;
        }

        ul {
          list-style-type: none;
          padding: 0;
          margin-top: 110px;
          opacity: 1;

          li {
            font-size: 22px;
            font-weight: bold;
            cursor: pointer;

            &:hover {
              color: red;

              i {
                color: red;
              }
            }
          }
        }
      }
    }
  }
  .img-map {
      padding-left: 9%;
      padding-top: 5%;
    }

  .menuhamburger {
    position: fixed;
    top: 10px;
    right: 7px;
    display: inline-block;
    cursor: pointer;
    z-index: 13;

    .bar1,
    .bar2,
    .bar3 {
      width: 25px;
      height: 3px;
      background-color: #333;
      margin: 6px 0;
      transition: 0.4s;
    }

    &.change .bar1 {
      -webkit-transform: rotate(-45deg) translate(-7px, 8px);
      transform: rotate(-45deg) translate(-7px, 8px);
    }

    &.change .bar2 {
      opacity: 0;
    }

    &.change .bar3 {
      -webkit-transform: rotate(45deg) translate(-5px, -6px);
      transform: rotate(45deg) translate(-5px, -6px);
    }
  }

  @media only screen and (min-width: 992px) {
    #travel {
      .travel-chose {
        div {
          .detail {
            .detail-hover {
              padding-right: 50px;
            }
          }

          &:hover {
            .detail-hover {
              opacity: 1;
            }
          }
        }
      }
    }

    #regions {
      .regions-detail {
        padding-top: 16.12%;
        padding-left: 70px;
        padding-right: 70px;

        h1 {
          font-size: 2.08vw;
          font-weight: bold;
          margin-bottom: 65px;
        }

        img {
          margin-bottom: 120px;
        }

        h3 {
          font-size: 2.08vw;
          font-weight: bold;
          margin-bottom: 20px;
        }

        p {
          padding-left: 50px;
          padding-right: 50px;
          font-size: 0.78vw;
        }
      }
    }

    .menuChose {
      .col {
        ul {
          list-style-type: none;
          padding: 0;
          margin-top: 110px;
          opacity: 0;

          li {
            font-size: 40px;
          }
        }
      }

      &.active {
        opacity: 1;
        visibility: visible;
        z-index: 12;

        .row {
          height: 100%;
        }

        .col {
          &:nth-child(1) {
            background-color: #e13036;
            height: 100%;
            width: 100%;
          }

          img {
            opacity: 1;
          }

          ul {
            list-style-type: none;
            padding: 0;
            margin-top: 110px;
            opacity: 1;

            li {
              font-size: 40px;
              font-weight: bold;
              cursor: pointer;

              &:hover {
                color: red;

                i {
                  color: red;
                }
              }
            }
          }
        }
      }
    }
  }

  .region-map {
    margin: 6.29vh auto;
  }
}
</style>



